import React, { useEffect, useState, useRef } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { GetItems } from '../api/ItemsAPI';
import Slides from '../components/ImageSliders';
import HtmlContent from '../components/HtmlContent';
import { GetPackageDetailsProductDetails } from '../api/PackageDetailsAPI';
import { GetPackageDetailsImagesPerItemId } from '../api/PackageDetailsImageAPI';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Breadcrumbs, Typography, Grid, ButtonGroup, Button } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { AddShoppingCart, CheckCircle, Check } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import {
    TextField,
    List,
    ListItem,
    ListItemText,
    Paper,
    CircularProgress,
    ListItemAvatar,
    Avatar,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    InputAdornment
} from '@mui/material';
import QuantityDropdownAddCart from '../components/QuantityDropdownAddCart';
import { useCart } from '../context/CartContext';
import { useNavigate } from 'react-router-dom';
import Divider from '@mui/material/Divider';
import WhatsApp from '@mui/icons-material/WhatsApp';
import { Helmet } from 'react-helmet';
import DumbleGIF from '../components/DumbleGIF';
import AppConfig from '../AppSettings';
import ProductCardFromList from '../components/ProductCardFromList';
import ProductCard from '../components/ProductCard';



const useStyles = makeStyles((theme) => ({
    containerAttention: {
        display: 'flex',
        overflowX: 'auto',
        padding: '20px 0',
        '&::-webkit-scrollbar': {
            display: 'none',
        }
    },
    tableContainer: {
        marginTop: '2px',
        marginBottom: '2px',
    },
    table: {
        minWidth: 300,
    },
    buttonGroupFlavor: { 
        flexWrap: 'wrap',
        paddingLeft: '50px',
        display: 'flex',
        marginBottom: '16px',
        justifyContent: 'left', 
    },
    buttonGroup: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '16px',
    },
    button: {
        transition: 'transform 0.3s ease-in-out',
    },
    container: {
        padding: '16px',
    },
    productDetails: {
        paddingLeft: '10px',
    },
    breadcrumb: {
        marginBottom: '16px',
    },
    selectedButton: {
        transform: 'scale(1.05)',
    },
}));

function ProductPD({ ProductToCart, onclosefct }) {
    const imageRef = useRef(null); 
    const classes = useStyles();
    const { itemName, size, flavor } = useParams();
    const location = useLocation();
    const locationProduct = location.state?.product || {};
    const [product, setProduct] = useState(locationProduct);
    const [productYouMayLike, setproductYouMayLike] = useState({});
    const [images, setImages] = useState([]);
    const [PDState, setPDState] = useState([]);

    const [selectedSize, setSelectedSize] = useState(null);
    const [selectedSizeIndex, setselectedSizeIndex] = useState(0);
    const [selectedFlavorIndex, setselectedFlavorIndex] = useState(0);
    const [selectedFlavor, setSelectedFlavor] = useState(null);
    const [PackadeDetailsPrice, setPackadeDetailsPrice] = useState('');

    const [servingPerContainer, setServingPerContainer] = useState('');

    const [formattedSize, setformattedSize] = useState('');
    const [formattedFlavor, setformattedFlavor] = useState('');

    const [IsFromDrawerAddToCart, setIsFromDrawerAddToCart] = useState(false);
    const [buttonState, setButtonState] = useState('idle'); // idle, loading, success
    const [loading, setLoading] = useState(true); // Add loading state


    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));

    const { addToCart } = useCart();
    const [quantity, setQuantity] = useState(1);  // Add state for quantity

    const handleClose = () => {
        onclosefct();
    };


    useEffect(() => { 
        setformattedSize(size ? decodeURIComponent(size) : null); // Decode size
        setformattedFlavor(flavor ? decodeURIComponent(flavor) : null); // Decode flavor


        if (ProductToCart && Object.keys(ProductToCart).length > 0) {
            setLoading(false); // Set loading to false
        }
        else {
            setLoading(true); // Set loading to true 
        }


        //secanrio when user click on item image from the card.
        setIsFromDrawerAddToCart(false);

        //scenario when user send the link to someone then need to handle this cause the context and state doesn't passed .
        const fetchData = async () => {
            try {
                //ge item name from state or from link 
                const formattedItemName = ProductToCart ? ProductToCart.ItemName : itemName.replace(/-/g, ' ');

                let paramsSelect = '';
                //if(size && flavor)
                //    paramsSelect = { ItemName: formattedItemName, SizeName: size, FlavorName :flavor}; 
                //else 
                paramsSelect = { ItemName: formattedItemName };

                const ItemResponse = await GetItems(paramsSelect);
                setProduct(ItemResponse?.Dt[0] || []);

                if (ItemResponse?.Dt[0]) {
                    const recentlyViewed = JSON.parse(localStorage.getItem('recentlyViewed')) || [];
                    // Remove any existing entry with the same item name
                    const updatedRecentlyViewed = recentlyViewed.filter(item => item.ItemName !== ItemResponse?.Dt[0].ItemName);
                    // Add the new product to the beginning of the array
                    updatedRecentlyViewed.unshift(ItemResponse?.Dt[0]);
                    // Keep only the latest 10 items
                    if (updatedRecentlyViewed.length > 10) {
                        updatedRecentlyViewed.pop();
                    }
                    localStorage.setItem('recentlyViewed', JSON.stringify(updatedRecentlyViewed));
                }



                const ItemResponseYoyMayLike = await GetItems({ IsItemVisible: true, SubCatId: ItemResponse?.SubCatId });
                setproductYouMayLike(ItemResponseYoyMayLike?.Dt?.slice(0, 7) || []);

                const packageDetailsResponse = await GetPackageDetailsProductDetails(paramsSelect);
                setPDState(packageDetailsResponse || []);

                const packageImagesResponse = await GetPackageDetailsImagesPerItemId(paramsSelect);  
                const extractedImages = packageImagesResponse?.Dt?.map(item => ({
                    name: item.PackageDetailsFileName,
                    PackageDetailID: item.PackageDetailsId
                })) || [];

                setImages(extractedImages);

            } catch (error) {
                console.error('Failed to fetch data', error);
            } finally {
                setLoading(false); // Set loading to false
            }
        };

        fetchData(); 

        if (ProductToCart && Object.keys(ProductToCart).length > 0) {
            //setProduct(ProductToCart);
            setIsFromDrawerAddToCart(true);
        }

    }, [itemName]);


    //useEffect(() => {
    //    const fetchData = async () => {
    //        if (product?.ItemId) {
    //            try {
    //                const paramsSelect = { ItemId: product.ItemId };

    //                const packageDetailsResponse = await GetPackageDetailsProductDetails(paramsSelect);
    //                setPDState(packageDetailsResponse || []);

    //                const packageImagesResponse = await GetPackageDetailsImagesPerItemId(paramsSelect);
    //                const extractedFileNames = packageImagesResponse?.Dt?.map(item => item.PackageDetailsFileName) || [];
    //                setImages(extractedFileNames);
    //            } catch (error) {
    //                console.error('Failed to fetch data', error);
    //            }
    //        }
    //    };

    //    fetchData();
    //}, [product?.ItemId]);

    useEffect(() => {
        if (PDState.length > 0) { 
            //handle case the user send the link by whatasap or open it by URl
            if (formattedSize && formattedFlavor) { 
                const IndexOfSelectedSize = PDState.findIndex(
                    sizeObj => sizeObj.SizeName.trim().toLowerCase() === formattedSize.trim().toLowerCase()
                );
                setSelectedSize(formattedSize);
                setselectedSizeIndex(IndexOfSelectedSize); 

                setServingPerContainer(PDState[IndexOfSelectedSize]?.ServingPerContainer);

                const IndexOfSelectedFlavor = PDState[IndexOfSelectedSize]?.Flavors?.findIndex(
                    flavorObj => flavorObj.FlavorName.trim().toLowerCase() === formattedFlavor.trim().toLowerCase()
                );
                //setSelectedFlavor(flavor);
                //setselectedFlavorIndex(IndexOfSelectedFlavor); 
                //setPackadeDetailsPrice(PDState[IndexOfSelectedSize]?.Flavors[IndexOfSelectedFlavor]?.CurrentPrice);

                //console.log("flavor", flavor)
                //console.log("IndexOfSelectedFlavor", IndexOfSelectedFlavor)
                //console.log("CurrentPrice", PDState[IndexOfSelectedSize]?.Flavors[IndexOfSelectedFlavor]?.CurrentPrice)
                //console.log("size", size)
                //console.log("IndexOfSelectedSize", IndexOfSelectedSize)

                handleFlavorSelect(formattedFlavor, IndexOfSelectedFlavor, PDState[IndexOfSelectedSize]?.Flavors[IndexOfSelectedFlavor]?.CurrentPrice, PDState[IndexOfSelectedSize]?.Flavors[IndexOfSelectedFlavor]?.PackageDetailID)

            }
            else {
                //default page load / default case 
                setSelectedSize(PDState[0]?.SizeName); 
                setselectedSizeIndex(0);
                setServingPerContainer(PDState[0]?.ServingPerContainer);
                setSelectedFlavor(PDState[0]?.Flavors[0]?.FlavorName);
                setselectedFlavorIndex(0);
                setPackadeDetailsPrice(PDState[0]?.Flavors[0]?.CurrentPrice);
            }
        }
    }, [PDState]);

    const handleSizeSelect = (size, index) => {  
        setSelectedSize(size);
        setselectedSizeIndex(index); 
        setServingPerContainer(PDState[index]?.ServingPerContainer);
        handleFlavorSelect(PDState[index]?.Flavors[0]?.FlavorName, 0, PDState[index]?.Flavors[0]?.CurrentPrice, PDState[index]?.Flavors[0]?.PackageDetailID)
    };

    const handleFlavorSelect = (flavor, index, price, packageDetailsId) => {
        setSelectedFlavor(flavor);
        setselectedFlavorIndex(index);
        setPackadeDetailsPrice(price);



        const delay = formattedFlavor && formattedSize ? 600 : 100; 
        // Add a delay before accessing the image
        setTimeout(() => { 
            // Construct the ID using packageDetailsId
            const imageElement = document.getElementById(`image-${packageDetailsId}`);
            if (imageElement) {   
                // Simulate a click on the element
                imageElement.click();

                if (window.innerWidth <= 1024) {
                    imageElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
                    window.scrollTo({ top: 80, behavior: 'smooth' });
                }
            }
        }, delay); // Delay in milliseconds (e.g., 300ms)
    };


    const handleWhatsAppShare = () => { 
        const url = `https://api.whatsapp.com/send?phone=${AppConfig?.PhoneNumberSendLinksWhats}&text=${encodeURIComponent('Check out this product:')} ${encodeURIComponent(`https://energize-lb.com/Product_PD/${product?.ItemName.replace(/\s/g, '-')}/${encodeURIComponent(selectedSize)}/${encodeURIComponent(selectedFlavor)}`)}`;

        window.open(url, '_blank');

    };


    // Retrieve and parse the recently viewed products from localStorage
    const recentlyViewed = JSON.parse(localStorage.getItem('recentlyViewed')) || [];

    const handleAddToCart = () => {

        setButtonState('loading');
        addToCart(product, selectedSize, selectedFlavor, PackadeDetailsPrice, quantity);

        setTimeout(() => {
            setButtonState('success');
            setTimeout(() => {
                setButtonState('idle');
            }, 2000);
        }, 2000);

    };
    //const fetchItemsToSend = async (data) => {
    //    try {
    //        const response = await GetItems(data);
    //        return response;
    //    } catch (error) {
    //        console.error('Failed to fetch items', error);
    //    }
    //};
    const brandNameClick = async (event, BrandName, Item) => {
        //const Itemsbybrand = await fetchItemsToSend({ BrandId: Item?.BrandId });
        //const ItemsToDisplay = Itemsbybrand?.Dt;
        navigate(`/ProductListByBrand/${BrandName.replace(/\s/g, '-')}`)
    };

    const handleQuantityChange = (newQuantity) => {
        setQuantity(newQuantity);
    };
    const navigate = useNavigate();

    if (loading) {
        return (
            <div className={classes.loader}>
                <DumbleGIF isLoading={loading}></DumbleGIF>
            </div>
        );
    }

    return (
        <div className={classes.container}>
            <Helmet>  
                <title>Energize - Product Details</title> 
            </Helmet>
            {IsFromDrawerAddToCart && (
                <IconButton
                    edge="end"
                    color="error"
                    onClick={handleClose}
                    aria-label="close"
                    sx={{ position: 'absolute', right: 8, top: 8 }}
                >
                    <CloseIcon />
                </IconButton>
            )}
            <br />
            {PDState ? (
                <Grid container spacing={3}>
                    {!IsFromDrawerAddToCart && (
                        <ThemeProvider theme={theme}>
                            <Grid item xs={12} md={6}> 

                                <Slides images={images}/>

                            </Grid>
                        </ThemeProvider>
                    )}
                    <Grid item xs={12} md={6} className={classes.productDetails}>
                        {!IsFromDrawerAddToCart && (
                            <Breadcrumbs separator={<ChevronRightIcon fontSize="small" />} aria-label="breadcrumb" className={classes.breadcrumb}>
                                <a className="breadcrumb-link" onClick={(event) => navigate('/')}>Home</a>
                                <span className="linkItemNameProductPd">{product?.ItemName}</span>
                            </Breadcrumbs>
                        )}
                        <Typography variant={!IsFromDrawerAddToCart ? "h4" : "body2"} className="ItemNameNewArrivalPackageDetCSS">{product?.ItemName}</Typography>
                        <br />
                        <Typography variant="body2" color="textSecondary" className="BrandNamePackageDetails" onClick={(event) => brandNameClick(event, product?.BrandName, product)} >
                            <u>{product?.BrandName}</u>
                        </Typography>
                        <br />
                        <Typography variant="h4" className="PackageDetPrice">${PackadeDetailsPrice}</Typography>
                        <br />


                        <div className="SizeDiv">
                            <div>
                                <b><Typography variant="subtitle1">Package Size:</Typography></b>
                                <Typography variant="caption">{selectedSize}</Typography>
                            </div>
                            <ButtonGroup aria-label="size selection" className={classes.buttonGroup}>
                                {PDState.map((detail, index) => (
                                    <Button
                                        style={{
                                            fontSize: '15px',
                                            margin: '0 5px',
                                            borderColor: selectedSize?.trim()?.toLowerCase() === detail?.SizeName?.trim()?.toLowerCase() ? 'blue' : 'gray',
                                            textTransform: 'none',
                                            color: 'black',
                                            borderRadius: '6px',

                                        }}
                                        key={index}
                                        variant={selectedSize?.trim()?.toLowerCase() === detail?.SizeName?.trim()?.toLowerCase() ? 'outlined' : 'outlined'}
                                        onClick={() => handleSizeSelect(detail?.SizeName, index)}
                                        className={`${classes.button} ${selectedSize === detail.SizeName ? classes.selectedButton : ''}`}
                                    >
                                        {detail?.SizeName}
                                    </Button>
                                ))}
                            </ButtonGroup>
                        </div>

                        <br /><br />
                        <div className="SizeDiv">
                            <div>
                                <b><Typography variant="subtitle1">Flavor:</Typography></b>
                            </div>

                            <ButtonGroup aria-label="flavor selection" orientation={isSmallScreen ? "horizontal" : "horizontal"} className={classes.buttonGroupFlavor}>
                                {PDState[selectedSizeIndex]?.Flavors?.map((Flvs, index) => (
                                    
                                    <Button
                                        style={{
                                            minWidth: '100px',
                                            maxWidth: '200px',
                                            maxHeight: '50px',
                                            fontSize: '15px',
                                            margin: '5px',
                                            borderColor: selectedFlavor?.trim()?.toLowerCase() === Flvs?.FlavorName?.trim()?.toLowerCase() ? 'blue' : 'gray',
                                            textTransform: 'none',
                                            borderRadius: '6px',
                                            backgroundColor: !Flvs.Enabled ? 'lightgray' : 'transparent', // Change background to gray if disabled
                                            color: !Flvs.Enabled ? 'gray' : 'black', // Change background to gray if disabled
                                            cursor: Flvs.Enabled ? 'pointer' : 'not-allowed' // Change cursor to not-allowed if disabled
                                        }}
                                        key={index}
                                        variant={selectedFlavor?.trim()?.toLowerCase() === Flvs?.FlavorName?.trim()?.toLowerCase() ? 'outlined' : 'outlined'}
                                        onClick={() => handleFlavorSelect(Flvs?.FlavorName, index, Flvs?.CurrentPrice, Flvs?.PackageDetailID)}
                                        className={`${classes.button} ${selectedFlavor?.trim()?.toLowerCase() === Flvs?.FlavorName?.trim()?.toLowerCase() ? classes.selectedButton : ''}`}
                                    > 
                                        {Flvs?.FlavorName}
                                    </Button>
                                ))}
                            </ButtonGroup>
                        </div>  
                        <br /><br />
                        <Grid container spacing={1} alignItems="center" className="ToolsProductDetails">
                            <Grid item xs={2} sm={3} md={4}>
                                <QuantityDropdownAddCart onChange={handleQuantityChange} />
                            </Grid>
                            <Grid item xs={6} sm={4} md={4}>
                                <Button
                                    variant="outlined"
                                    className={`AddToCartPackageDetailsBtn ${buttonState}`}
                                    onClick={handleAddToCart}
                                    disabled={buttonState === 'loading'}
                                >
                                    {buttonState === 'idle' && (
                                        <>
                                            Add to cart
                                            <AddShoppingCart sx={{ color: 'rgb(90,151,145)' }} />
                                        </>
                                    )}
                                    {buttonState === 'loading' && <CircularProgress size={24} />}
                                    {buttonState === 'success' && <CheckCircle className="AddedCheckCircleCardBtn" />}
                                </Button>
                            </Grid>
                            <Grid item xs={4} sm={4} md={4}>
                                <Button
                                    variant="outlined"
                                    className="WhatsAppButton"
                                    onClick={handleWhatsAppShare}
                                    style={{
                                        color: 'black',
                                        height: '55px',
                                        textTransform: 'none',
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    Chat on
                                    <WhatsApp className="vibratingIcon" style={{ color: '#25D366' }} />
                                </Button>
                            </Grid>
                        </Grid>
                        {!IsFromDrawerAddToCart && (
                            <div>
                                <br />
                                <br />

                                <Divider />
                                <HtmlContent
                                    title="Serving Per Container"
                                    htmlString={servingPerContainer}
                                    defaultCollapsed={false} />
                                <Divider />

                                <HtmlContent
                                    title="Nutrition Facts"
                                    htmlString={product?.ItemNutritionFacts}
                                    defaultCollapsed={false} />

                                <HtmlContent
                                    title="Description"
                                    htmlString={product?.ItemDescription}
                                    defaultCollapsed={true} />

                                <HtmlContent
                                    title="Ingredients"
                                    htmlString={product?.ItemIngredients}
                                    defaultCollapsed={true} />

                                <HtmlContent
                                    title="Directions"
                                    htmlString={product?.ItemDirections}
                                    defaultCollapsed={true} />

                                <HtmlContent
                                    title="Warnings"
                                    htmlString={product?.ItemWarnings}
                                    defaultCollapsed={true} />


                                <HtmlContent
                                    title="Support your Goals"
                                    htmlString={product?.ItemSupportGoals}
                                    defaultCollapsed={true} />

                                <HtmlContent
                                    title="About The Brand"
                                    htmlString={product?.AboutBrand}
                                    defaultCollapsed={true} />

                            </div>
                        )}

                    </Grid>


                    {!IsFromDrawerAddToCart && (
                        <>
                            <br />
                            <Typography
                                sx={{
                                    display: 'block', // Forces it to be a block element
                                    width: '100%', // Ensures it takes the full width of the container
                                    paddingLeft: 5,
                                    paddingTop: 7,
                                    marginBottom: 2 // Adjust margin as needed
                                }}
                                className="ItemNameNewArrivalPackageDetCSS"
                            >
                                - Related Products / you may also like</Typography>
                            <div className={classes.containerAttention}>
                                {productYouMayLike.map((product, index) => (
                                    <ProductCard
                                        product={product}
                                        key={index}
                                        brand={product.BrandName}
                                        image={product.ItemFileName ? `${AppConfig.ImageUrl}/${product.ItemFileName}` : null}
                                        name={product.ItemName}
                                        description={product.ItemDescription}
                                        price={product.ItemPrice}
                                        onCardClick={() => navigate(`/Product_PD/${product?.ItemName.replace(/\s/g, '-')}`)}
                                        SmallCard={true}
                                    />
                                ))}
                            </div>

                            <Typography
                                sx={{
                                    display: 'block', // Forces it to be a block element
                                    width: '100%', // Ensures it takes the full width of the container
                                    paddingLeft: 5,
                                    paddingTop: 7,
                                    marginBottom: 2 // Adjust margin as needed
                                }}
                                className="ItemNameNewArrivalPackageDetCSS"
                            >
                                - Recently Viewed</Typography>
                            <div className={classes.containerAttention}>
                                {recentlyViewed?.map((product, index) => (
                                    <ProductCard
                                        product={product}
                                        key={index}
                                        brand={product.BrandName}
                                        image={product.ItemFileName ? `${AppConfig.ImageUrl}/${product.ItemFileName}` : null}
                                        name={product.ItemName}
                                        description={product.ItemDescription}
                                        price={product.ItemPrice}
                                        onCardClick={() => navigate(`/Product_PD/${product?.ItemName.replace(/\s/g, '-')}`)}
                                        SmallCard={true}
                                    />
                                ))}
                            </div>
                        </>
                    )}

                </Grid>

            ) : (
                <p>No product details provided.</p>
            )}
        </div>
    );
}

export default ProductPD;
