import React, { useState } from 'react';
import DOMPurify from 'dompurify';
import { IconButton, Collapse, Typography, Box, Divider, Paper } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const HtmlContent = ({ title, htmlString, defaultCollapsed = true }) => {
    const [open, setOpen] = useState(!defaultCollapsed);

    // Sanitize the HTML string
    const sanitizedHtml = DOMPurify.sanitize(htmlString);

    const handleToggle = () => {
        setOpen(!open);
    };

    // Check if htmlString is not empty or just whitespace
    const hasContent = htmlString && htmlString.trim() !== '' && htmlString !== "<p><br></p>";

    return (
        hasContent && (
            <Box sx={{ mb: 1 }}>
                <Paper sx={{ padding: 1, borderRadius: 1, boxShadow: 1 }}>
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between" 
                        sx={{
                            cursor: 'pointer',
                            '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.05)' },
                            transition: 'background-color 0.3s',
                        }}
                        onClick={handleToggle}
                    >
                        <Typography variant="h6" sx={{ fontWeight: 'bold', color: 'rgb(44, 58, 71)' }}>
                            {title}
                        </Typography>

                        <IconButton
                            onClick={handleToggle}
                            sx={{
                                color: 'rgb(90, 151, 145)',
                                transition: 'transform 0.3s ease',
                                transform: open ? 'rotate(180deg)' : 'rotate(0deg)', // Smooth rotation on click
                                '&:hover': { transform: 'scale(1.1)' },
                            }}
                        >
                            {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </IconButton>
                    </Box>

                    {/* Collapse animation using simple height transition */}
                    <Collapse
                        in={open}
                        timeout="auto"
                        unmountOnExit
                        sx={{
                            overflow: 'hidden', // To ensure that collapsed content is hidden
                            transition: 'height 0.3s ease-out', // Smooth height transition
                            height: open ? 'auto' : 0, // Ensures that height transitions when collapsed
                        }}
                    >
                        <Box
                            sx={{
                                opacity: open ? 1 : 0,
                                transition: 'opacity 0.3s ease-in-out', // Smooth opacity transition
                            }}
                            dangerouslySetInnerHTML={{ __html: sanitizedHtml }}
                        />
                    </Collapse>
                     
                </Paper>
            </Box>
        )
    );
};

export default HtmlContent;
