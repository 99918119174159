import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import DeleteIcon from '@mui/icons-material/Delete';
import { useCart } from '../context/CartContext';
import { List, ListItem, ListItemText, Typography, Divider, Button, Grid, Dialog, DialogContent, DialogTitle, IconButton, TextField, Box } from '@mui/material';
import AppConfig from '../AppSettings'; 
import WhatsApp from '@mui/icons-material/WhatsApp';
import { useNavigate } from 'react-router-dom';

const ViewCart = ({ open, onClose }) => {

    const navigate = useNavigate();

    const { cart, updateQuantity, removeFromCart } = useCart();

    const handleClose = () => {
        onClose();
    };

    const handleQuantityChange = (event, index, delta) => {
        event.stopPropagation();

        const newQuantity = cart[index].quantity + delta;
        if (newQuantity > 0) {
            updateQuantity(index, newQuantity);
        }
    };
    const handleRemoveChange = (event, index) => {
        event.stopPropagation();
        removeFromCart(index);
         
    };

    const handleWhatsAppShare = () => {
        const productLinks = cart.map((item) => {
            const productUrl = `https://energize-lb.com/Product_PD/${encodeURIComponent(item.ItemName.replace(/\s/g, '-'))}/${encodeURIComponent(item.selectedSize)}/${encodeURIComponent(item.selectedFlavor)}`;
            return `Check out this product: ${productUrl}`;
        }).join('\n\n');

        const total = cart.reduce((total, item) => total + parseFloat(item.selectedPrice) * item.quantity, 0).toFixed(2);

        const message = `${productLinks}\n\nTotal: $${total}`;

        const url = `https://api.whatsapp.com/send?phone=${AppConfig?.PhoneNumberSendLinksWhats}&text=${encodeURIComponent(message)}`;
        window.open(url, '_blank');
    };
     



    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            sx={{
                '& .MuiPaper-root': {
                    maxWidth: '800px',
                    borderRadius: '15px',
                    overflow: 'hidden',
                    marginTop: '0',
                    height: '100vh',
                    width: '100vw',
                    position: 'absolute',
                    top: 0,
                },
            }}
        >
            <DialogTitle id="form-dialog-title">
                <IconButton
                    edge="end"
                    color="error"
                    onClick={handleClose}
                    aria-label="close"
                    sx={{ position: 'absolute', right: 8, top: 8 }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent sx={{ padding: { xs: 2, sm: 3 } }}>
                <div style={{ padding: '16px' }}> 
                    <Grid container justifyContent="space-between" alignItems="center" sx={{ marginBottom: 2 }}>
                        <Grid item>
                            <Typography variant="h4" gutterBottom>Shopping Cart</Typography>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="outlined"
                                className="WhatsAppButton"
                                onClick={handleWhatsAppShare}
                                style={{
                                    color: 'black',
                                    height: '55px',
                                    textTransform: 'none',
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                Chat on
                                <WhatsApp className="vibratingIcon" style={{ color: '#25D366', marginLeft: 8 }} />
                            </Button>
                        </Grid>
                    </Grid> 

                    <Divider />
                    <List>
                        {cart.map((item, index) => (
                            <ListItem
                                key={index}
                                alignItems="flex-start"
                                onClick={() => {
                                    const formattedItemName = item?.ItemName ? item.ItemName.replace(/\s/g, '-') : '';
                                    const formattedSize = item?.selectedSize ? encodeURIComponent(item.selectedSize) : '';
                                    const formattedFlavor = item?.selectedFlavor ? encodeURIComponent(item.selectedFlavor) : '';

                                    navigate(`/Product_PD/${formattedItemName}/${formattedSize}/${formattedFlavor}`);
                                    handleClose();
                                }}
                            >
                                <img src={`${AppConfig.ImageUrl}/${item?.ItemFileName}`} alt={item.ItemName} style={{ width: '100px', height: '100px', marginRight: '16px' }} />
                                <ListItemText
                                    primary={item.ItemName}
                                    secondary={
                                        <>
                                            <Typography variant="body2" color="textSecondary">
                                                <b>Brand:</b> {item.BrandName}
                                            </Typography>
                                            <Typography variant="body2" color="textSecondary">
                                                <b>Size:</b> {item.selectedSize}
                                            </Typography>
                                            <Typography variant="body2" color="textSecondary">
                                                <b>Flavor:</b> {item.selectedFlavor}
                                            </Typography>
                                            <Typography variant="body2" color="textSecondary">
                                            <br/>
                                                <Typography variant="h6">
                                                    ${item.selectedPrice}
                                                </Typography>

                                                <Box display="flex" alignItems="center" sx={{ marginTop: '8px' }}>
                                                    <IconButton
                                                        size="small"
                                                        onClick={(event) => handleQuantityChange(event,index, -1)}
                                                    >
                                                        <RemoveIcon />
                                                    </IconButton>
                                                    <TextField
                                                        value={item.quantity}
                                                        inputProps={{ min: 1, readOnly: true }}
                                                        size="small"
                                                        sx={{ maxWidth: '50px', textAlign: 'center'}}
                                                    />
                                                    <IconButton
                                                        size="small"
                                                        onClick={(event) => handleQuantityChange(event,index, 1)}
                                                    >
                                                        <AddIcon />
                                                    </IconButton>

                                                    <IconButton
                                                        color="secondary"
                                                        onClick={(event) => handleRemoveChange(event,index)}    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </Box>
                                            </Typography>
                                            <br/>
                                            <Divider />
                                          
                                        </>
                                    }
                                />

                            </ListItem>

                        ))}

                    </List>
                    <Divider />
                    <Grid container justifyContent="space-between" style={{ marginTop: '16px' }}>
                        <Typography variant="h6">Total: ${cart.reduce((total, item) => total + parseFloat(item.selectedPrice) * item.quantity, 0).toFixed(2)}</Typography>
                        {/*<Button variant="contained" color="primary">Proceed to Checkout</Button>*/}
                    </Grid> 
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default ViewCart;
