import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Alert from '@mui/material/Alert';
import { InsertSize, UpdateSize } from '../api/SizesAPI';
import RichTextbox from './RichTextbox';

const SizeDialogForm = ({ open, item, onClose, onSubmit }) => {
    const [error, setError] = useState(null);

    const validationSchema = Yup.object({
        SizeName: Yup.string().required('Name is required'),
    });

    const handleClose = () => {
        setError(null); // Clear error state
        onClose();
    };

    const handleNumberChange = (handleChange) => (event) => {
        const { name, value } = event.target;
        // Ensure the value is stored as a number in the form state
        handleChange({ target: { name, value: value === '' ? null : Number(value) } });
    };


    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" sx={{
            '& .MuiPaper-root': {
                maxWidth: '400px', // Optional: Set max-width for larger screens
                /* margin: '0 auto',*/

            },
        }} >
            <DialogTitle>{item ? 'Edit Size' : 'Add New Size'}</DialogTitle>
            <Formik
                initialValues={{
                    SizeId: item ? item.SizeId : null,
                    SizeName: item ? item.SizeName : null,
                    ServingPerContainer: item ? item.ServingPerContainer : null,
                }}
                validationSchema={validationSchema}
                onSubmit={async (values, { setSubmitting }) => {
                    try {
                        if (item) {
                            const UpdateRes = await UpdateSize(values);
                            const errorMsg = UpdateRes?.Error?.Message;
                            if (errorMsg) {
                                setError('Error updating Size: ' + errorMsg, 'error');
                                return;
                            }
                        } else {
                            const InsertRes = await InsertSize(values);
                            const errorMsg = InsertRes?.Error?.Message;
                            if (errorMsg) {
                                setError('Error adding Size: ' + errorMsg, 'error');
                                return;
                            }
                        }
                        onSubmit();
                    } catch (error) {
                        console.error('Failed to submit Cat form', error);
                        setError('Failed to submit Cat form. Please try again.'); // Set error message
                    } finally {
                        setSubmitting(false);
                    }
                }}
            >
                {({ handleSubmit, handleChange, values, errors, touched, setFieldValue }) => (
                    <Form >
                        <DialogContent >
                            {error && <Alert severity="error">{error}</Alert>}
                            <TextField
                                required
                                id="txtName"
                                label="Name"
                                name="SizeName"
                                value={values.SizeName}
                                onChange={handleChange}
                                fullWidth
                                margin="dense"
                                error={touched.SizeName && Boolean(errors.SizeName)}
                                helperText={touched.SizeName && errors.SizeName}
                            />
                            <br/>
                            <br/>
                            <RichTextbox
                                title="Serving Per Container"
                                name="ServingPerContainer"
                                value={values.ServingPerContainer}
                                onChange={(value) => setFieldValue('ServingPerContainer', value)} 

                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose} color="secondary">
                                Cancel
                            </Button>
                            <Button onClick={handleSubmit} color="primary">
                                {item ? 'Update' : 'Add'}
                            </Button>
                        </DialogActions>
                    </Form>
                )}
            </Formik>
        </Dialog>
    );
};

export default SizeDialogForm;
